<template>
  <div class="main-container">
    <h1 class="main-heading">{{$t('ASSETS')}}</h1>
    <div class="add-asset-container">
      <input id="asset-file" type="file" name="asset-file" multiple @change="handleAssetFileChange" />
      <button v-if="assetExists" class="error" @click="removeAssetFile">{{ $t("REMOVE") }}</button>
      <button v-if="assetExists" class="success" @click="addToAssets">{{ $t("ADD") }}</button>
      <span v-if="assetExists && assetNameExists" class="warning-text">{{ $t("ASSET_SAME_NAME_WARNING_TEXT") }}</span>
    </div>
    <asset-gallery :asset-list="assetList" :cdnQuery="query" />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, onBeforeMount, onBeforeUnmount} from "vue";
import axios from "axios";
import dispatchMap from "@/constants/dispatchMap";

import AssetGallery from "@/components/AssetGallery";
import { notify } from '@kyvg/vue3-notification';
export default {
  name: "Assets",
  components: {
    "asset-gallery": AssetGallery
  },
  setup() {
    const store = useStore();
    const usedPages = ["assets"];
    onBeforeMount(() => usedPages.forEach(page => {
      dispatchMap[page].forEach(dispatchStr => store.dispatch(dispatchStr));
    }));
    const assetList = computed(() => store.getters['assets/getAssets'].files);
    const query = computed(() => store.getters['assets/getAssets'].query);

    const refreshAssets = () => {
      store.dispatch("assets/getAssets")
    }
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));
    const assetExists = ref(false);
    const assetNameExists = ref(false);
    const currentSelectedFiles = ref([])

    const handleAssetFileChange = e => {
      const importedFiles = e.target.files ? Array.prototype.slice.call(e.target.files).map(x => x.name) : []
      currentSelectedFiles.value = importedFiles
      assetExists.value = importedFiles.length > 0;
      assetNameExists.value = assetList.value.map(x => x.doc_ref).filter(value => importedFiles.includes(value)).length > 0
    }

    const removeAssetFile = () => {
      document.getElementById("asset-file").value = null;
      assetExists.value = false;
    }
    
    const clearReplacedAssets = async () => {
      const assetsToDelete = assetList.value.filter(value => currentSelectedFiles.value.includes(value.doc_ref))
      for (let i = 0; i < assetsToDelete.length; i++) {
        await store.dispatch("assets/deleteAsset", assetsToDelete[i])
      }
    }

    const addToAssets = async () => {
      //TODO: Add to assets
      await clearReplacedAssets()
      const fileElement = document.getElementById("asset-file");
      let data;

      for (let i = 0; i < fileElement.files.length; i++) {
        data = new FormData();
        data.append("file", fileElement.files[i])
        axios
            .post("/api/assets/add", data, {
              headers: {
                "Content-type": "multipart/form-data"
              },
            })
            .then(() => {
              notify({
                type: 'success',
                title: 'Success',
                text: "Asset added"
              })
              refreshAssets()
            })
            .catch((err) => {
              notify({
                type: 'error',
                title: 'Error',
                text: err
              })
            })
      }

      removeAssetFile()
    }

    return {
      assetList,
      refreshAssets,
      removeAssetFile,
      assetExists,
      assetNameExists,
      handleAssetFileChange,
      addToAssets,
      query
    }
  }
}
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  min-height: 350px;
  width: 100%;
  display: grid;
  grid-template-columns: 6fr 4fr;
}

.form-container {
  display: flex;
  margin: 15px 0;
}

.form-container p {
  width: 60%;
  text-align: start;
}
.form-container input, .form-container div, .form-container select {
  width: 40%;
}
#creation_date, #expire_date {
  width: 100%;
  height: 100%;
  padding: 0;
}

.buttons-section {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: stretch;
}

.buttons-section button {
  color: black;
  font-size: 1.25rem;
}

button.error {
  background-color: rgba(255, 0, 0, 0.15);
}

button.success {
  background-color: rgba(0, 255, 0, 0.15);
}

button.info {
  background-color: rgba(0, 0, 255, 0.15);
}

.col {
  padding: 15px;
}
.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr 2fr 2fr 2fr 1fr;
  grid-column-gap: 10px;
  margin: 10px 0;
}
.row.header-row {
  background-color: #cccccc;
  font-weight: bold;
}
.form-container.inline {
  display: flex;
  flex-direction: column;
}
button {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: 1px solid gray;
  padding: 5px 20px;
  margin: 5px 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.form-info {
  color: gray;
  font-size: 0.75rem;
  text-align: start;
}

.warning-text {
  font-size: 0.9rem;
  color: rgba(255, 0, 0, 0.8);
}
</style>