<template>
  <div class="buttons-section">
    <button class="error" @click="toggleMultiSelectMode">{{ multiSelectMode ? "Select Single" : "Select Multiple" }}</button>
    <button v-if="multiSelectMode" class="error" @click="deleteSelectedAssets">{{ "Delete Selected Assets" }}</button>
    <button class="error" @click="openConfirm">{{ "Delete All" }}</button>
  </div>
  <div class="image-gallery">
    <template v-if="assetList">
      <div v-for="asset in assetList" v-bind:key="asset.doc_ref" :class="selectedAssets.includes(asset) ? 'image-thumbnail-container-filtered' : 'image-thumbnail-container'" @click="onClickImage(asset)">
<!--        <image-with-cookie class="image-thumbnail" :cookie="cookie" :url="asset.url" />-->
        <img class="image-thumbnail" :src="asset.url + cdnQuery" />
        <p class="thumbnail-title">{{ asset.doc_ref }}</p>
      </div>
    </template>
  </div>
  <div v-if="popupAsset" class="popup-wrapper" :class="popupActive ? 'active' : ''">
    <div class="popup-overlay"  @click="closePopup"></div>
    <div class="popup-container">
      <h2>{{ popupAsset.doc_ref }}</h2>
<!--      <image-with-cookie class="popup-image" :cookie="cookie" :url="popupAsset.url" />-->
      <img class="popup-image" :src="popupAsset.url + cdnQuery" />

      <div class="popup-controls">
        <button v-if="!confirmationActive" class="error" @click="toggleConfirmation">{{ $t("REMOVE" )}}</button>
        <div v-else class="popup-confirmation">
          <span class="confirmation-warning">Are you sure to delete this asset?</span>
          <button class="success" @click="toggleConfirmation">No, don't delete</button>
          <button class="error" @click="deleteAsset">Yes, delete</button>
        </div>
      </div>
    </div>
  </div>
  <ConfirmPopup :popup-open="popupOpen" :text="popupText" :type="popupType" @popup-close="popupOpen=false" @confirm="deleteAllAssets"/>
</template>

<script>
import { onUpdated, ref } from "vue";
import { useStore } from "vuex";
import { getFileName } from "../util/generic"
import ConfirmPopup from './common/ConfirmPopup.vue'

export default {
  name: "AssetGallery",
  props: [ "assetList", "cdnQuery" ],
  components: {
    ConfirmPopup
  },
  setup() {
    const store = useStore();
    const popupActive = ref(false);
    const popupAsset = ref(null);
    onUpdated(() => store.dispatch("loader/loadingStatus", false));

    const selectedAssets = ref([])
    const multiSelectMode = ref(false)

    const openPopup = asset => {
      popupAsset.value = asset;
      popupActive.value = true;
    }
    const popupOpen = ref(false);
    const popupText = ref("Are you sure? Change you want to make cannot be reverted.");
    const popupType = ref("confirm");
    const onClickImage = asset => {
      if (multiSelectMode.value) {
        if (selectedAssets.value.includes(asset))
          selectedAssets.value.pop(asset)
        else selectedAssets.value.push(asset)
      } else {
        popupAsset.value = asset;
        popupActive.value = true;
      }
    }

    const toggleMultiSelectMode = () => {
      multiSelectMode.value = !multiSelectMode.value
      if (!multiSelectMode.value)
        selectedAssets.value = []
    }

    const closePopup = () => {
      popupActive.value = false;
      confirmationActive.value = false;
      popupOpen.value = false;
    }

    const confirmationActive = ref(false);
    const toggleConfirmation = () => confirmationActive.value = !confirmationActive.value;

    const  deleteAllAssets = async () => {
      await store.dispatch("assets/deleteAllAssets");
      console.log("delete all dispatched")
      closePopup();
    }

    const deleteSelectedAssets = async () => {
      for (let i = 0; i < selectedAssets.value.length; i++) {
        store.dispatch("assets/deleteAsset", selectedAssets.value[i])
      }
      selectedAssets.value = []
    }

    const deleteAsset = () => {
      //TODO: Delete activeAsset
      store.dispatch("assets/deleteAsset", popupAsset.value)
      closePopup()
    }

    const openConfirm = () => {
      popupOpen.value = true;
    }


    return {
      popupActive,
      popupAsset,
      openPopup,
      closePopup,
      confirmationActive,
      toggleConfirmation,
      deleteAsset,
      onClickImage,
      toggleMultiSelectMode,
      multiSelectMode,
      selectedAssets,
      deleteAllAssets,
      getFileName,
      popupOpen,
      popupText,
      popupType,
      deleteSelectedAssets,
      openConfirm
    }

  }
}
</script>

<style scoped>
  .image-gallery {
    width: 100%;
    display: grid;
    padding: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .buttons-section {
    margin: 20px;
  }

  .image-thumbnail-container {
    margin: 5px;
    height: 100px;
    border-radius: 5px;
    border: 1px solid black;
    overflow: hidden;
    cursor: pointer;
  }

  .image-thumbnail-container-filtered{
    margin: 5px;
    height: 100px;
    border-radius: 5px;
    border: 1px solid black;
    overflow: hidden;
    cursor: pointer;
    filter: grayscale(100%);
  }

  .image-thumbnail {
    height: 70px;
  }

  .thumbnail-title {
    height: 20px;
    margin: 0;
  }

  .popup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;
  }

  .popup-wrapper.active {
    display: flex;
  }

  .popup-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
  }

  .popup-container {
    z-index: 1;
    width: 40%;
    max-height: 80%;
    background-color: white;
    border-radius: 15px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .popup-image {
    max-width: 70%;
    max-height: 70%;
  }

  button.error {
    background-color: rgba(255, 0, 0, 0.15);
  }

  button.success {
    background-color: rgba(0, 255, 0, 0.15);
  }

  button.info {
    background-color: rgba(0, 0, 255, 0.15);
  }

  button {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    border: 1px solid gray;
    padding: 5px 20px;
    margin: 5px 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    color: black
  }

</style>